const G01SpreadInfo = {
  sheetName: 'G01',
  headerRowCount: 2,
  headerColumnCount: 22,
  headerColumns: [
    {
      index: 0,
      name: '清单项目编号',
      width: '110'
    },
    {
      index: 1,
      name: '工程或费用项目名称',
      width: '280'
    },
    {
      index: 2,
      name: '单位',
      width: '60'
    },
    {
      index: 3,
      name: '单价',
      width: '60'
    },
    {
      index: 4,
      name: '合同数量',
      children: [
        {
          index: 4,
          name: '原合同数量',
          width: '90'
        },
        {
          index: 5,
          name: '按施工图调整后数量',
          width: '90'
        },
        {
          index: 6,
          name: '变更数量',
          width: '90'
        },
        {
          index: 7,
          name: '变更后数量',
          width: '90'
        },
        {
          index: 8,
          name: '工作量（元）',
          width: '90'
        }
      ]
    },
    {
      index: 9,
      name: '本年计划',
      children: [
        {
          index: 9,
          name: '工程量',
          width: '90'
        },
        {
          index: 10,
          name: '工作量（元）',
          width: '90'
        }
      ]
    },
    {
      index: 11,
      name: '本月计划',
      children: [
        {
          index: 11,
          name: '工程量',
          width: '90'
        },
        {
          index: 12,
          name: '工作量（元）',
          width: '90'
        }
      ]
    },
    {
      index: 13,
      name: '本月完成',
      children: [
        {
          index: 13,
          name: '工程量',
          width: '90'
        },
        {
          index: 14,
          name: '工作量（元）',
          width: '90'
        },
        {
          index: 15,
          name: '占月计划%',
          width: '90'
        }
      ]
    },
    {
      index: 16,
      name: '至月末本年度累计完成',
      children: [
        {
          index: 16,
          name: '工程量',
          width: '90'
        },
        {
          index: 17,
          name: '工作量（元）',
          width: '90'
        },
        {
          index: 18,
          name: '占年计划%',
          width: '90'
        }
      ]
    },
    {
      index: 19,
      name: '全部工程累计完成',
      children: [
        {
          index: 19,
          name: '工程量',
          width: '90'
        },
        {
          index: 20,
          name: '工作量（元）',
          width: '90'
        },
        {
          index: 21,
          name: '占合同%',
          width: '90'
        }
      ]
    }
  ]
}

export default G01SpreadInfo
